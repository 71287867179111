/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import {
  fetchCurrentOrders,
  fetchOrdersToday,
} from "../redux/actions/orderActions";
import { fetchSettings } from "../redux/actions/settingActions";
import {
  timePickerSodertalje,
  timePickerPolhem,
  timePickerFoodtruck,
  timePickerUppsala,
  timePickerUppsala2,
  STORES,
} from "../util";
import Loader from "./Loader";
import "./Cart.css";
import * as Svgs from "../assets";
import FooterPayment from "./FooterPayment";

const Cart = ({
  settings,
  isFetchingSettings,
  fetchCurrentOrders,
  fetchSettings,
  fetchOrdersToday,
  ordersToday,
}) => {
  useEffect(() => {
    fetchSettings();
    fetchOrdersToday();
  }, []);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [deliveryTime, setDeliveryTime] = useState("Tid för avhämtning");
  const history = useHistory();
  let orderList = JSON.parse(localStorage.getItem("orderList"));

  const [currentOrderList, setCurrentOrderList] = useState(orderList);
  const initialSum = _.sumBy(orderList, "price");
  const [orderSummary, setOrderSummary] = useState(initialSum);
  const serverSettings = settings.settings[0];
  const serverClientVersion = serverSettings && serverSettings.clientVersion;
  const storedSettings = JSON.parse(localStorage.getItem("settings"));
  const storedClientVersion = storedSettings && storedSettings.clientVersion;
  const store = localStorage.getItem("selectedStore");
  const weekday = moment().weekday();
  const timeNow = moment();
  const afterClosed = timeNow.isAfter(moment("20:31", "HH:mm"));

  if (
    serverClientVersion &&
    serverClientVersion != null &&
    serverClientVersion !== storedClientVersion
  ) {
    console.log("UPDATE VERSION");
    localStorage.setItem("orderList", JSON.stringify([]));
    localStorage.setItem("discountedOrderSum", 0);
    localStorage.setItem("discountedOrderList", JSON.stringify([]));
    localStorage.setItem("deliveryTime", null);
    localStorage.setItem("orderSum", 0);
    localStorage.setItem("selectedStore", "Välj ställe för avhämtning");
    history.push("/");
    window.location.reload(true);
  }

  if (isFetchingSettings && !serverSettings) {
    return <Loader />;
  }

  if (!orderList || orderList.length === 0) {
    orderList = [];
  }

  const checkForSelectedOptions = (currentOrderPrice) => {
    const oL = JSON.parse(localStorage.getItem("orderList"));
    let extraCosts = 0;

    oL.forEach((pizza) => {
      if (pizza.sliced) {
        extraCosts += 5;
      }
      if (pizza.veganCheese) {
        extraCosts += 15;
      }
      if (pizza.glutenFree) {
        extraCosts += 35;
      }
    });

    const sum = currentOrderPrice + extraCosts;
    localStorage.setItem("orderSum", sum);
    setOrderSummary(sum);
  };

  const updateSums = (delTime) => {
    const timeFormat = "HH:mm";
    const timeDelivery = moment(delTime, timeFormat);
    const lunchStart = moment("10:59", timeFormat);
    const lunchEnd = moment("14:01", timeFormat);
    const lunchTime = timeDelivery.isBetween(lunchStart, lunchEnd);
    const dayMoment = moment();
    const whatDay = dayMoment.isoWeekday();
    let currentOrderPrice = 0;

    if (lunchTime && whatDay < 6) {
      let lunchSum = 0;
      let specialPizzaSum = 0;
      let specialPastaSum = 0;
      if (
        localStorage.getItem("selectedStore") === STORES.POLHEMSGATAN_ST
      ) {
        lunchSum = orderList.filter((o) => o.pizzaNbr < 85).length * 120;
        const specialPizzasList = orderList.filter(
          (o) =>
            (o.pizzaNbr >= 200 && o.pizzaNbr <= 299) ||
            o.pizzaNbr === 501 ||
            (o.pizzaNbr > 800 && o.pizzaNbr < 850)
        );
        specialPizzaSum = _.sumBy(specialPizzasList, "price");
        const specialPastaList = orderList.filter(
          (o) => ((o.pizzaNbr >= 300 && o.pizzaNbr <= 370) &&
           (o.pizzaNbr !== 301 && o.pizzaNbr !== 303))
        );
        specialPastaSum = _.sumBy(specialPastaList, "price");
      }

      lunchSum += specialPizzaSum;
      lunchSum += specialPastaSum;

      let lunchPastaSum = 0;
      let lunchSalladSum = 0;
      let lunchSodaSum = 0;
      lunchPastaSum =
        orderList.filter((o) => o.pizzaNbr === 301 || o.pizzaNbr === 303).length *
        120;

      lunchSalladSum =
        orderList.filter((o) => o.pizzaNbr >= 400 && o.pizzaNbr <= 499).length *
        120;

      lunchSodaSum = orderList.filter((o) => o.pizzaNbr === 98).length * 24;
      currentOrderPrice =
        lunchSum + lunchPastaSum + lunchSalladSum + lunchSodaSum;
      checkForSelectedOptions(
        lunchSum + lunchPastaSum + lunchSalladSum + lunchSodaSum
      );
      localStorage.setItem("lunchTime", true);
    } else {
      const ordinarySum = _.sumBy(orderList, "price");
      currentOrderPrice = ordinarySum;
      localStorage.setItem("orderSum", currentOrderPrice);
      localStorage.setItem("lunchTime", false);
      setOrderSummary(currentOrderPrice);
    }
    forceUpdate();
  };
  const validateTimeSlot = async (time) => {
    let getOrdersForTime = await fetchCurrentOrders(time);
    getOrdersForTime = getOrdersForTime.filter(
      (p) => p.store === localStorage.getItem("selectedStore")
    );
    let selectedTimeSlotCount = 0;
    for (let i = 0; i < getOrdersForTime.length; i++) {
      selectedTimeSlotCount += getOrdersForTime[i].orderItemsPizzas;
    }
    const myOrderList = orderList.filter((p) => p.pizzaNbr < 60).length;
    const orderSizeFitsSlot = selectedTimeSlotCount + myOrderList <= 6;

    if (!orderSizeFitsSlot) {
      const spotsLeft = 6 - selectedTimeSlotCount;

      const spotString =
        spotsLeft === 0 ? "" : `Platser kvar i vald tid är ${spotsLeft}`;
      Swal.fire({
        title: "Fel!",
        text: `Tyvärr, vald tid är fullbokad. Vänligen välj en annan tid. ${spotString}`,
        icon: "error",
        confirmButtonColor: "#10AD7D",
        confirmButtonText: "OK",
      });
    }
    return orderSizeFitsSlot;
  };

  const validateTimeSlotFoodtruck = async (time) => {
    let getOrdersForTime = await fetchCurrentOrders(time);
    getOrdersForTime = getOrdersForTime.filter(
      (p) => p.store === localStorage.getItem("selectedStore")
    );
    let selectedTimeSlotCount = 0;
    for (let i = 0; i < getOrdersForTime.length; i++) {
      selectedTimeSlotCount += getOrdersForTime[i].orderItemsPizzas;
    }
    const myOrderList = orderList.filter((p) => p.pizzaNbr < 60).length;
    const orderSizeFitsSlot = selectedTimeSlotCount + myOrderList <= 2;

    if (!orderSizeFitsSlot) {
      const spotsLeft = 2 - selectedTimeSlotCount;

      const spotString =
        spotsLeft === 0 ? "" : `Platser kvar i vald tid är ${spotsLeft}`;
      Swal.fire({
        title: "Fel!",
        text: `Tyvärr, vald tid är fullbokad. Vänligen välj en annan tid. ${spotString}`,
        icon: "error",
        confirmButtonColor: "#10AD7D",
        confirmButtonText: "OK",
      });
    }
    return orderSizeFitsSlot;
  };

  const selectPickupTime = async (time) => {
    setDeliveryTime(time);
    const today = moment().format("YYYY-MM-DD");
    const timeAndDate = moment(`${today} ${time}`).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    localStorage.setItem("deliveryTime", time);
    localStorage.setItem("deliveryTimestamp", timeAndDate);
    localStorage.getItem("selectedStore").includes("Foodtruck")
      ? await validateTimeSlotFoodtruck(time)
      : await validateTimeSlot(time);
    updateSums(time);
  };

  const gotoCheckout = async () => {
    if (deliveryTime === "Tid för avhämtning") {
      Swal.fire({
        title: "Fel!",
        text: "Du måste välja en tid för upphämtning",
        icon: "error",
        confirmButtonColor: "#10AD7D",
        confirmButtonText: "OK",
      });
    } else if (orderSummary === 0) {
      Swal.fire({
        title: "Fel!",
        text: "Din kundvagn är tom",
        icon: "error",
        confirmButtonColor: "#10AD7D",
        confirmButtonText: "OK",
      });
    } else if (deliveryTime !== "Tid för avhämtning") {
      const orderFits = localStorage
        .getItem("selectedStore")
        .includes("Foodtruck")
        ? await validateTimeSlotFoodtruck(deliveryTime)
        : await validateTimeSlot(deliveryTime);
      if (
        deliveryTime !== "Tid för avhämtning" &&
        orderSummary > 0 &&
        orderFits
      ) {
        window.scrollTo(0, 0);
        history.push("/checkout");
      }
    }
  };

  const ordersPerSlot = (time) => {
    const orders = ordersToday.filter(
      (p) =>
        p.deliveryTime === time &&
        p.store === localStorage.getItem("selectedStore")
    );
    const slotSum = _.sumBy(orders, "orderItemsPizzas");
    return slotSum;
  };

  const timePickerFiltered = () => {
    const currentOrderSize = orderList.filter((p) => p.pizzaNbr < 60).length;
    let timePickerArr = [];

    if (localStorage.getItem("selectedStore") === STORES.GJUTERIPORTEN_ST) {
      timePickerArr = timePickerSodertalje();
    }

    if (localStorage.getItem("selectedStore") === STORES.POLHEMSGATAN_ST) {
      timePickerArr = timePickerPolhem();
    }

    if (localStorage.getItem("selectedStore") === STORES.FOODTRUCK1) {
      timePickerArr = timePickerFoodtruck();
    }

    if (localStorage.getItem("selectedStore") === STORES.UPPSALA) {
      timePickerArr = timePickerUppsala();
    }

    if (localStorage.getItem("selectedStore") === STORES.UPPSALA2) {
      timePickerArr = timePickerUppsala2();
    }

    for (let i = 0; i < timePickerArr.length; i++) {
      const time = timePickerArr[i] ? timePickerArr[i] : 0;
      const willItFit = currentOrderSize + ordersPerSlot(time);
      if (
        localStorage.getItem("selectedStore") === STORES.FOODTRUCK1 &&
        willItFit > 2
      ) {
        timePickerArr.splice(i, 1);
      }

      if (
        localStorage.getItem("selectedStore") !== STORES.FOODTRUCK1 &&
        willItFit > 6
      ) {
        timePickerArr.splice(i, 1);
      }
    }
    return timePickerArr;
  };

  const updateOptions = (pizza, option, index) => {
    let sum = orderSummary;
    const curr = currentOrderList;

    if (option === "S") {
      if (!pizza.sliced) {
        sum += 5;
        pizza.extra = `${pizza.extra} [S]`;
        pizza.price += 5;
        pizza.sliced = true;
      } else {
        sum -= 5;
        pizza.extra = pizza.extra.replace(" [S]", "");
        pizza.price -= 5;
        pizza.sliced = false;
      }
    } else if (option === "VO") {
      if (!pizza.veganCheese) {
        sum += 15;
        pizza.extra = `${pizza.extra} [VO]`;
        pizza.price += 15;
        pizza.veganCheese = true;
      } else {
        sum -= 15;
        pizza.extra = pizza.extra.replace(" [VO]", "");
        pizza.price -= 15;
        pizza.veganCheese = false;
      }
    } else if (option === "GF") {
      if (!pizza.glutenFree) {
        sum += 35;
        pizza.extra = `${pizza.extra} [GF]`;
        pizza.price += 35;
        pizza.glutenFree = true;
      } else {
        sum -= 35;
        pizza.extra = pizza.extra.replace(" [GF]", "");
        pizza.price -= 35;
        pizza.glutenFree = false;
      }
    }
    curr[index] = pizza;
    setCurrentOrderList(curr);
    setOrderSummary(sum);
    localStorage.setItem("orderSum", sum);
    localStorage.setItem("orderList", JSON.stringify(currentOrderList));
    forceUpdate();
  };

  const gotoMaps = () => {
    // fixa sen
    // window.location.href = mapUrl;
  };

  const maxSlots = localStorage.getItem("selectedStore").includes("Foodtruck")
    ? 2
    : 6;
  const showAvailableslots = (time) => {
    return "(" + (maxSlots - ordersPerSlot(time)) + ")";
  };

  return (
    <div>
      <div className="cart_headerRow">
        <h2 className="header2">BESTÄLLNING</h2>
        <div className="cart_headerCheckout" />
        <div className="cart_headerBackButtonContainer">
          <button
            type="button"
            className="pizzas_headerBackButton"
            onClick={() => history.push("/pizzas")}
          >
            <img src={Svgs.leftArrow} alt="" />
          </button>
        </div>
      </div>
      <div className="cart_orderContainer">
        {orderList.map((pizza, i) => (
          <div key={pizza.id} className="cart_pizzaRow">
            <div className="cart_leftSiderow">
              <div className="cart_pizzaUpperRow">
                <div className="cart_pizzaRowLeftSide">
                  <span className="pizzas_pizzaNameText">{pizza.name}</span>
                  {pizza.id === 98}
                </div>
              </div>
            </div>
            <div>
              {(pizza.pizzaNbr < 90 ||
                (pizza.pizzaNbr >= 200 && pizza.pizzaNbr <= 299)) && (
                <div className="cart_rightSideOptions">
                  <button
                    type="button"
                    className="cart_optionsButtons"
                    onClick={() => updateOptions(pizza, "S", i)}
                  >
                    {pizza.sliced ? (
                      <img src={Svgs.slicedSelected} alt="" />
                    ) : (
                      <img src={Svgs.sliced} alt="" />
                    )}
                  </button>

                  <button
                    type="button"
                    className="cart_optionsButtons"
                    onClick={() => updateOptions(pizza, "VO", i)}
                  >
                    {pizza.veganCheese ? (
                      <img src={Svgs.veganSelected} alt="" />
                    ) : (
                      <img src={Svgs.vegan} alt="" />
                    )}
                  </button>

                  {!localStorage
                    .getItem("selectedStore")
                    .includes("Foodtruck") && (
                    <button
                      type="button"
                      className="cart_optionsButtons"
                      onClick={() => updateOptions(pizza, "GF", i)}
                    >
                      {pizza.glutenFree &&
                      !localStorage
                        .getItem("selectedStore")
                        .includes("Foodtruck") ? (
                        <img src={Svgs.glutenSelected} alt="" />
                      ) : (
                        <img src={Svgs.gluten} alt="" />
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="cart_optionsInfoRow">
          <div className="cart_optionsInfoRowInner">
            <img src={Svgs.sliced} alt="" />
            <div className="cart_optionsInfoColInner">
              <span className="cart_optionsText">Sliced pizza</span>
              <span className="cart_plusPrice">+ 5kr</span>
            </div>
          </div>
          <div className="cart_optionsInfoRowInner">
            <img src={Svgs.vegan} alt="" />
            <div className="cart_optionsInfoColInner">
              <span className="cart_optionsText">Vegansk ost</span>
              <span className="cart_plusPrice">+ 15kr</span>
            </div>
          </div>
          {!localStorage.getItem("selectedStore").includes("Foodtruck") && (
            <div className="cart_optionsInfoRowInner">
              <img
                src={Svgs.gluten}
                alt=""
                data-tip="Innehållsförteckning: Glutenfri stärkelse (ris, majs, rent havre, bovete, teff, potatis, tapioka) (44%), vatten, rapsolja, socker, mjölkpulver, psylliumfröskal, salt, jäst, förtjockningsmedel (xantangummi), bakpulver. Glutenhalt lägre än 20 ppm."
              />
              <div className="cart_optionsInfoColInner">
                <ReactTooltip
                  type="light"
                  place="right"
                  multiline
                  offset={{ top: 10, left: 50 }}
                />
                <span
                  className="cart_optionsText"
                  data-tip="Innehållsförteckning: Glutenfri stärkelse (ris, majs, rent havre, bovete, teff, potatis, tapioka) (44%), vatten, rapsolja, socker, mjölkpulver, psylliumfröskal, salt, jäst, förtjockningsmedel (xantangummi), bakpulver. Glutenhalt lägre än 20 ppm."
                >
                  Glutenfri botten
                </span>
                <span
                  className="cart_plusPrice"
                  data-tip="Innehållsförteckning: Glutenfri stärkelse (ris, majs, rent havre, bovete, teff, potatis, tapioka) (44%), vatten, rapsolja, socker, mjölkpulver, psylliumfröskal, salt, jäst, förtjockningsmedel (xantangummi), bakpulver. Glutenhalt lägre än 20 ppm."
                >
                  + 35kr
                </span>
              </div>
              <img
                src={Svgs.smallInfo}
                className="cart_smallInfo"
                data-tip="Innehållsförteckning: Glutenfri stärkelse (ris, majs, rent havre, bovete, teff, potatis, tapioka) (44%), vatten, rapsolja, socker, mjölkpulver, psylliumfröskal, salt, jäst, förtjockningsmedel (xantangummi), bakpulver. Glutenhalt lägre än 20 ppm."
                alt=""
              />
            </div>
          )}
        </div>

        <div className="cart_pickupPlace">
          <span className="cart_pickupPlaceText">Avhämtning:</span>
          <span className="cart_pickupPlaceLocation">{store}</span>
        </div>

        <div className="cart_pickupPlaceSub">
          <button
            type="button"
            className="home_iconButton"
            onClick={() => gotoMaps()}
          >
            <div className="cart_mapButtonDiv">
              <img className="home_mapIcon" src={Svgs.mapPin} alt="" />
              {store === STORES.POLHEMSGATAN_ST && (
                <span className="cart_pickupPlaceLocationMap">
                  Polhemsgatans 3A, Södertälje
                </span>
              )}
            </div>
          </button>
        </div>

        <div className="cart_timePickerContainer">
          <select
            className="cart_timePickerDropdown"
            onChange={(e) => selectPickupTime(e.target.value)}
            value={deliveryTime}
          >
            {timePickerFiltered().map((t) => (
              <option key={t} value={t}>
                {t} {t !== "Tid för avhämtning" && showAvailableslots(t)}
              </option>
            ))}
          </select>

          <span className="cart_timePickerLabel">
            *Siffra inom parentes (x) anser antal platser kvar per tidsslot.
          </span>

          <span className="cart_timePickerLabel">
            Lunchpris 120:- gäller mellan 11.00-14.00 på vardagar. Gäller ej
            månadens eller Pizza Di lusso.
          </span>
        </div>
        <div className="cart_paymentContainer">
          <span className="cart_summaryText">Summa</span>
          <span className="cart_summarySubText">{orderSummary} kr</span>
        </div>
        <div className="cart_paymentButtonView">
          <button
            type="button"
            disabled={afterClosed}
            className={
              afterClosed || deliveryTime === "Tid för avhämtning"
                ? "cart_paymentButton_disabled"
                : "cart_paymentButton"
            }
            onClick={() => gotoCheckout()}
          >
            Vidare till betalning
            {weekday === 1 || (afterClosed && "(Stängt)")}
          </button>
        </div>
      </div>
      <div className="cart_deadSpace" />
      <FooterPayment />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.products.loading,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
  currentOrders: state.orders.currentOrders,
  currentOrdersError: state.orders.currentOrdersError,
  currentOrdersLoading: state.orders.currentOrdersLoading,
  ordersToday: state.orders.ordersToday,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  fetchCurrentOrders: (time) => dispatch(fetchCurrentOrders(time)),
  fetchOrdersToday: () => dispatch(fetchOrdersToday()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
