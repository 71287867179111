/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-lonely-if */
/* eslint-disable operator-linebreak */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { fetchProducts } from "../redux/actions/productActions";
import { fetchSettings } from "../redux/actions/settingActions";
import { uniqueId, STORES, storeClosed, storeClosedManually } from "../util";
import Loader from "./Loader";
import Footer from "./Footer";

import * as Svgs from "../assets";
import "./Pizzas.css";

const Pizzas = ({
  fetchProducts,
  isFetingProducts,
  fetchSettings,
  isFetchingSettings,
  products,
  settings,
}) => {
  const history = useHistory();
  const [store, setStore] = useState("Välj ställe för avhämtning");
  useEffect(() => {
    ongoingOrder();
    fetchProducts();
    fetchSettings();
    const hasStore = localStorage.getItem("selectedStore");
    if (
      hasStore != "null" &&
      hasStore != null &&
      hasStore !== "" &&
      hasStore !== "Välj ställe för avhämtning"
    ) {
      setStore(hasStore);
    }
  }, []);

  const [orderCount, setOrderCount] = useState(0);
  const productsFromStorage = JSON.parse(localStorage.getItem("products"));

  const storeSettings =
    settings && settings.settings.filter((s) => s.store === store);
  const serverSettings =
    storeSettings.length > 0 ? storeSettings[0] : settings.settings[0];
  const serverClientVersion = serverSettings && serverSettings.clientVersion;
  const storedSettings = JSON.parse(localStorage.getItem("settings"));
  const storedClientVersion = storedSettings && storedSettings.clientVersion;

  if (
    serverClientVersion &&
    serverClientVersion != null &&
    serverClientVersion !== storedClientVersion
  ) {
    console.log("UPDATE VERSION");
    localStorage.setItem("orderList", JSON.stringify([]));
    localStorage.setItem("discountedOrderSum", 0);
    localStorage.setItem("discountedOrderList", JSON.stringify([]));
    localStorage.setItem("deliveryTime", null);
    localStorage.setItem("orderSum", 0);
    localStorage.setItem("selectedStore", "Välj ställe för avhämtning");
    localStorage.setItem("lunchTime", false);
    history.push("/");
    window.location.reload(true);
  }

  let productsList = [];
  if (serverSettings) {
    localStorage.setItem("settings", JSON.stringify(serverSettings));
  }

  if (productsFromStorage) {
    productsList = productsFromStorage;
  }

  if (products) {
    localStorage.setItem("products", JSON.stringify(products));
    productsList = products;
  }

  let orderList = JSON.parse(localStorage.getItem("orderList"));

  if (!orderList || orderList.length === 0) {
    orderList = [];
  }

  const itemCount = (item) => _.countBy(orderList, { id: item.id }).true;

  const addItem = (pizza) => {
    const currentOrder = JSON.parse(localStorage.getItem("orderList"));
    const pizzaCount = currentOrder.filter(
      (p) => p.pizzaNbr < 60 || (p.pizzaNbr > 200 && p.pizzaNbr < 250)
    ).length;
    if (localStorage.getItem("selectedStore") === STORES.FOODTRUCK1) {
      if (
        pizzaCount < 2 ||
        pizza.pizzaNbr === 98 ||
        (pizza.pizzaNbr > 250 && currentOrder.length < 20)
      ) {
        currentOrder.push({
          ...pizza,
          uniqueId: uniqueId(),
          extra: "",
          sliced: false,
          veganCheese: false,
          glutenFree: false,
        });
        localStorage.setItem("orderList", JSON.stringify(currentOrder));
        const add = orderCount + 1;
        setOrderCount(add);
      } else {
        Swal.fire({
          title: "",
          text: "Önskar du fler än 2 pizzor vänligen lägg en beställning till, gäller foodtruck",
          icon: "info",
          confirmButtonColor: "#10AD7D",
          confirmButtonText: "OK",
          timer: 4000,
        });
      }
    } else {
      if (
        pizzaCount < 6 ||
        pizza.pizzaNbr === 98 ||
        (pizza.pizzaNbr > 250 && currentOrder.length < 20)
      ) {
        currentOrder.push({
          ...pizza,
          uniqueId: uniqueId(),
          extra: "",
          sliced: false,
          veganCheese: false,
          glutenFree: false,
        });
        localStorage.setItem("orderList", JSON.stringify(currentOrder));
        const add = orderCount + 1;
        setOrderCount(add);
      } else {
        Swal.fire({
          title: "",
          text: "Önskar du fler än 6 pizzor vänligen lägg en beställning till",
          icon: "info",
          confirmButtonColor: "#10AD7D",
          confirmButtonText: "OK",
          timer: 4000,
        });
      }
    }
  };

  const removeItem = (pizza) => {
    const currentOrder = JSON.parse(localStorage.getItem("orderList"));
    const pizzaIsInList = currentOrder.filter((p) => p.id === pizza.id);
    if (pizzaIsInList.length > 0) {
      const uniq = currentOrder.filter((p) => p.id === pizza.id)[0].uniqueId;
      const updateOrder = currentOrder.filter((p) => p.uniqueId !== uniq);
      localStorage.setItem("orderList", JSON.stringify(updateOrder));
      setOrderCount(updateOrder.length);
    }
  };

  const ongoingOrder = () => {
    const ongoing = JSON.parse(localStorage.getItem("orderList"));
    if (ongoing !== null) {
      const pizzasAndSoda = ongoing.filter((p) => p.pizzaNbr !== 99);
      setOrderCount(pizzasAndSoda.length);
    } else {
      localStorage.setItem("orderList", JSON.stringify([]));
    }
  };

  const gotoCart = () => {
    if (orderCount === 0) {
      Swal.fire({
        title: "Fel!",
        text: "Du har inte valt några pizzor",
        icon: "error",
        confirmButtonColor: "#10AD7D",
        confirmButtonText: "OK",
        timer: 4000,
      });
    } else {
      window.scrollTo(0, 0);
      history.push("/cart");
    }
  };

  if (isFetchingSettings && !serverSettings) {
    return <Loader />;
  }

  if (isFetingProducts && productsList.length === 0) {
    return <Loader />;
  }

  const pizzaListPolhem = productsList.filter(
    (p) => p.pizzaNbr >= 1 && p.pizzaNbr <= 40
  );

  const pizzaListFoodtruck = productsList.filter(
    (p) => p.pizzaNbr >= 1 && p.pizzaNbr <= 40
  );

  const pizzaLussoListPolhem = productsList.filter(
    (p) =>
      p.pizzaNbr === 207 ||
      p.pizzaNbr === 208 ||
      p.pizzaNbr === 203 ||
      p.pizzaNbr === 205 ||
      p.pizzaNbr === 209 ||
      p.pizzaNbr === 206 ||
      p.pizzaNbr === 211
  );
  const pizzaLussoListFoodtruck = productsList.filter(
    (p) =>
      p.pizzaNbr === 203 ||
      p.pizzaNbr === 205 ||
      p.pizzaNbr === 206 ||
      p.pizzaNbr === 208
  );

  const pastaList = productsList.filter(
    (p) => p.pizzaNbr >= 300 && p.pizzaNbr <= 399
  );
  const salladList = productsList.filter(
    (p) => p.pizzaNbr >= 400 && p.pizzaNbr <= 499
  );
  const sodaList = productsList.filter((p) => p.pizzaNbr === 98);
  const dessertList = productsList.filter((p) => p.pizzaNbr === 501);
  
  const selectedStore = localStorage.getItem("selectedStore");

  const whatList = () => {
    switch (selectedStore) {
      case STORES.POLHEMSGATAN_ST:
        return pizzaListPolhem;
      default:
        return pizzaListFoodtruck;
    }
  };

  const whatLussoList = () => {
    switch (selectedStore) {
      case STORES.POLHEMSGATAN_ST:
        return pizzaLussoListPolhem;
      default:
        return pizzaLussoListFoodtruck;
    }
  };

  const goToHome = () => {
    history.push("/");
  };

  return (
    <div>
      <div className="pizzas_headerRow">
        <h2 className="header2">Meny</h2>
        <div className="pizzas_headerCheckout">
          <button
            type="button"
            disabled={storeClosed(selectedStore)}
            className="pizzas_headerCheckoutButton"
            onClick={() => gotoCart()}
          >
            <img src={Svgs.cart} alt="" />
            <span className="pizzas_checkoutCounter">{orderCount}</span>
          </button>
        </div>

        <div className="pizzas_headerBackButtonContainer">
          <button
            type="button"
            className="pizzas_headerBackButton"
            onClick={() => goToHome()}
          >
            <img src={Svgs.leftArrow} alt="" />
          </button>
        </div>
      </div>
      <div>
        <span className="pizzas_pizzaHeader">PIZZA</span>
      </div>
      <div className="pizzas_pizzaListContainer">
        {whatList().map((pizza) => (
          <div key={pizza.id} className="pizzas_pizzaRow">
            <div className="pizzas_leftSiderow">
              <div className="pizzas_pizzaUpperRow">
                <div className="pizzas_pizzaRowLeftSide">
                  <span className="pizzas_pizzaNameText">
                    {pizza.name}{" "}
                    <span className="pizzas_pizzaPrice"> {pizza.price} kr</span>
                  </span>
                </div>
              </div>
              <div className="pizzas_pizzaSubRow">
                <span className="pizzas_pizzaDesc">{pizza.description}</span>
              </div>
            </div>
            <div className="pizzas_rightSideBox">
              <button
                type="button"
                className="pizzas_plusButton"
                onClick={() => addItem(pizza)}
              >
                <img src={Svgs.plusBtn} alt="" />
              </button>
              <span className="pizzas_itemCountText">
                {itemCount(pizza) ? itemCount(pizza) : 0}
              </span>
              <button
                type="button"
                className="pizzas_minusButton"
                onClick={() => removeItem(pizza)}
              >
                <img src={Svgs.minusBtn} alt="" />
              </button>
            </div>
          </div>
        ))}
        <br />
        <div>
          <span className="pizzas_pizzaHeader">PIZZA DI LUSSO</span>
        </div>
        {whatLussoList().map((pizza) => (
          <div key={pizza.id} className="pizzas_pizzaRow">
            <div className="pizzas_leftSiderow">
              <div className="pizzas_pizzaUpperRow">
                <div className="pizzas_pizzaRowLeftSide">
                  <span className="pizzas_pizzaNameText">
                    {pizza.pizzaNbr === 299 && <span>MÅNADENS SPECIAL: </span>}
                    {pizza.name}{" "}
                    <span className="pizzas_pizzaPrice">{pizza.price} kr</span>
                  </span>
                </div>
              </div>
              <div className="pizzas_pizzaSubRow">
                <span className="pizzas_pizzaDesc">{pizza.description}</span>
              </div>
            </div>
            <div className="pizzas_rightSideBox">
              <button
                type="button"
                className="pizzas_plusButton"
                onClick={() => addItem(pizza)}
              >
                <img src={Svgs.plusBtn} alt="" />
              </button>
              <span className="pizzas_itemCountText">
                {itemCount(pizza) ? itemCount(pizza) : 0}
              </span>
              <button
                type="button"
                className="pizzas_minusButton"
                onClick={() => removeItem(pizza)}
              >
                <img src={Svgs.minusBtn} alt="" />
              </button>
            </div>
          </div>
        ))}
        <br />
        {/* ändra här STORES.POLHEMSGATAN_ST */}
        {localStorage.getItem("selectedStore") === STORES.POLHEMSGATAN_ST && (
          <div>
            <br />
            <div className="pizzas_pastaHeaderDiv">
              <span className="pizzas_pastaHeader">PASTA</span>
              <span className="pizzas_pastaHeader2" />
            </div>
            {pastaList.map((pizza) => (
              <div key={pizza.id} className="pizzas_pizzaRow">
                <div className="pizzas_leftSiderow">
                  <div className="pizzas_pizzaUpperRow">
                    <div className="pizzas_pizzaRowLeftSide">
                      <span className="pizzas_pizzaNameText">
                        {pizza.name}{" "}
                        <span className="pizzas_pizzaPrice">
                          {pizza.price} kr
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pizzas_pizzaSubRow">
                    <span className="pizzas_pizzaDesc">
                      {pizza.description}
                    </span>
                  </div>
                </div>
                <div className="pizzas_rightSideBox">
                  <button
                    type="button"
                    className="pizzas_plusButton"
                    onClick={() => addItem(pizza)}
                  >
                    <img src={Svgs.plusBtn} alt="" />
                  </button>
                  <span className="pizzas_itemCountText">
                    {itemCount(pizza) ? itemCount(pizza) : 0}
                  </span>
                  <button
                    type="button"
                    className="pizzas_minusButton"
                    onClick={() => removeItem(pizza)}
                  >
                    <img src={Svgs.minusBtn} alt="" />
                  </button>
                </div>
              </div>
            ))}
            <br />
            <div className="pizzas_pastaHeaderDiv">
              {/* hide for now
              <span className="pizzas_pastaHeader">SALLAD</span>
            */}
              <span className="pizzas_pastaHeader2" />
            </div>
            {salladList.map((pizza) => (
              <div key={pizza.id} className="pizzas_pizzaRow">
                <div className="pizzas_leftSiderow">
                  <div className="pizzas_pizzaUpperRow">
                    <div className="pizzas_pizzaRowLeftSide">
                      <span className="pizzas_pizzaNameText">
                        {pizza.name}{" "}
                        <span className="pizzas_pizzaPrice">
                          {pizza.price} kr
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pizzas_pizzaSubRow">
                    <span className="pizzas_pizzaDesc">
                      {pizza.description}
                    </span>
                  </div>
                </div>
                <div className="pizzas_rightSideBox">
                  <button
                    type="button"
                    className="pizzas_plusButton"
                    onClick={() => addItem(pizza)}
                  >
                    <img src={Svgs.plusBtn} alt="" />
                  </button>
                  <span className="pizzas_itemCountText">
                    {itemCount(pizza) ? itemCount(pizza) : 0}
                  </span>
                  <button
                    type="button"
                    className="pizzas_minusButton"
                    onClick={() => removeItem(pizza)}
                  >
                    <img src={Svgs.minusBtn} alt="" />
                  </button>
                </div>
              </div>
            ))}

            <br />

            <div className="pizzas_pastaHeaderDiv">
              {/*
              hide for now
              <span className="pizzas_pastaHeader">DESSERT</span>
            */}
              <span className="pizzas_pastaHeader2" />
            </div>
            {dessertList.map((pizza) => (
              <div key={pizza.id} className="pizzas_pizzaRow">
                <div className="pizzas_leftSiderow">
                  <div className="pizzas_pizzaUpperRow">
                    <div className="pizzas_pizzaRowLeftSide">
                      <span className="pizzas_pizzaNameText">
                        {pizza.name}{" "}
                        <span className="pizzas_pizzaPrice">
                          {pizza.price} kr
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pizzas_pizzaSubRow">
                    <span className="pizzas_pizzaDesc">
                      {pizza.description}
                    </span>
                  </div>
                </div>
                <div className="pizzas_rightSideBox">
                  <button
                    type="button"
                    className="pizzas_plusButton"
                    onClick={() => addItem(pizza)}
                  >
                    <img src={Svgs.plusBtn} alt="" />
                  </button>
                  <span className="pizzas_itemCountText">
                    {itemCount(pizza) ? itemCount(pizza) : 0}
                  </span>
                  <button
                    type="button"
                    className="pizzas_minusButton"
                    onClick={() => removeItem(pizza)}
                  >
                    <img src={Svgs.minusBtn} alt="" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <br />
        <div>
          <span className="pizzas_pizzaHeader">DRYCK</span>
        </div>
        {sodaList.map((pizza) => (
          <div key={pizza.id} className="pizzas_pizzaRow">
            <div className="pizzas_leftSiderow">
              <div className="pizzas_pizzaUpperRow">
                <div className="pizzas_pizzaRowLeftSide">
                  <span className="pizzas_sodaPriceText">{pizza.price} kr</span>
                </div>
              </div>
              <div className="pizzas_sodaSubRow">
                <span className="pizzas_pizzaDesc">
                  {pizza.description}
                  {localStorage.getItem("selectedStore") ===
                    STORES.POLHEMSGATAN_ST && ", Clean"}
                </span>
              </div>
            </div>
            <div className="pizzas_rightSideBox">
              <button
                type="button"
                className="pizzas_plusButton"
                onClick={() => addItem(pizza)}
              >
                <img src={Svgs.plusBtn} alt="" />
              </button>
              <span className="pizzas_itemCountText">
                {itemCount(pizza) ? itemCount(pizza) : 0}
              </span>
              <button
                type="button"
                className="pizzas_minusButton"
                onClick={() => removeItem(pizza)}
              >
                <img src={Svgs.minusBtn} alt="" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="pizzas_gotoOrderView">
        <div className="pizzas_gotoOrderButtonView">
          <button
            type="button"
            disabled={
              storeClosed(selectedStore) ||
              storeClosedManually(selectedStore, settings)
            }
            className={
              storeClosed(selectedStore) ||
              storeClosedManually(selectedStore, settings) ||
              orderList.length === 0
                ? "pizzas_gotoOrderButton_disabled"
                : "pizzas_gotoOrderButton"
            }
            onClick={() => gotoCart()}
          >
            {storeClosed(selectedStore) ||
            storeClosedManually(selectedStore, settings)
              ? "(Stängt, se öppettider på föregående sida)"
              : "Se beställning"}
          </button>
        </div>
      </div>

      <div className="pizzas_pizzaInformationContainer">
        <ReactTooltip
          type="light"
          place="right"
          multiline
          offset={{ top: 10, left: 50 }}
        />
        <p
          className="pizzas_pizzaInformationText"
          data-tip="Degen görs på Tipo 00 mjöl och innehåller ingen mjölkprotein eller ägg."
        >
          Vad innehåller er deg?
          <br />
          Vi handhar allergener
        </p>
        <p
          className="pizzas_pizzaInformationText"
          data-tip="DOP står för Denominazione di Origine Protetta. En produkt som är märkt med D.O.P. härstammar från ett avgränsat område som bidrar med vissa kvaliteter till produkten samt att produktionen utförs i samma område under mycket kontrollerade regler. EU tilldelar märkningen."
        >
          Vad betyder dop?
        </p>
        <p
          className="pizzas_pizzaInformationText"
          data-tip="Du kan ta bort ingredienser men inte lägga till. Kom till avhämtningsstället 15 minuter innan och säg till"
        >
          Om jag vill ta bort ingredienser, hur gör jag?
        </p>
      </div>

      <div className="pizzas_deadSpace" />

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetchingSettings: state.settings.loading,
  isFetingProducts: state.products.loading,
  products: state.products.items,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(fetchProducts()),
  fetchSettings: () => dispatch(fetchSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pizzas);
